<template>
  <div id="EquipmentDetailDialog" ref="EquipmentDetailDialog">
    <a-modal v-model="visible" :title="title"  :width="1200" :getContainer="() => this.$refs.EquipmentDetailDialog" >
        <div style="height:640px;width:100%;">
            <equipment-detail :id="equipmentId" :equipment="pequipment" :kind="0" :iconList="piconList" :pointGrs="ppointGrs"></equipment-detail>
        </div>
    </a-modal>
  </div>
</template>
<script>
import EquipmentDetail from './equipmentDetail.vue';
export default {
  name: 'EquipmentDetailDialog',
  props: {
    
    id: {
      type: Number,
      default:0,
    },
    equipment:{
        type:Object,
        default:{}
    },
    equipmentSiteId: {
      type: Number,
      default:0,
    },
    iconList:{
        type:Array,
        default:[],
    },
    pointGrs:{
        type:Array,
        default:[],
    }
  },
  data() {
        return {
            visible:false,
            title:'sdd',
            equipmentId:0,
            ppointGrs:[],
            piconList:[],
            pequipment:{},
        };
  },
  mounted(){
   
  },
  components:{
      "equipment-detail":EquipmentDetail,
  },
  watch:{
    "$parent.detailDialogVisible":{
      handler(val){
          if(val){              
              this.visible = val;
              console.log('this.equipment',this.equipment);
              this.title = this.equipment.name;
              this.equipmentId = this.id;
              this.ppointGrs = this.pointGrs;
              this.piconList = this.iconList;
              this.pequipment = this.equipment;
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          if(!val){
              this.$parent.detailDialogVisible = val;
              this.equipmentId=0;
            this.ppointGrs=[];
            this.piconList=[];
            this.pequipment={};
          }
      },
      deep:true,
      immediate:true,
    },
  },
  methods: {
    renderItem(item) {
        const customLabel = (
            <span class="custom-item">
             {item.text}
            </span>
        );

        return {
            label: customLabel, // for displayed item
            value: item.no, // for title and filter matching
        };
    },
    
  },
};
</script>
<style scoped>
    #EquipmentDetailDialog{
        height: 100%;
        width:100%;
        position: relative;
    }
</style>